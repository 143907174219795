import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { FormGroup } from '@angular/forms';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { Store, Select } from '@ngxs/store';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { Observable } from 'rxjs';
import { PanelInfoState } from 'common-web-ui';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'app-pilot-information',
  templateUrl: './pilot-information.component.html',
  styleUrls: ['./pilot-information.component.less']
})
export class PilotInformationComponent implements OnInit {
  @Input() readonly: boolean;

  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;

  @Input() state: PanelInfoState;

  isCustomerWebAppAutoLoadPresetsFeatureEnabled: boolean;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAutoLoadPresets))
      .subscribe(isEnabled => {
        this.isCustomerWebAppAutoLoadPresetsFeatureEnabled = !!isEnabled;
      });
  }
}
