<app-form-card>
  <div class="container" [ngClass]="{ hidden: !(currentTrainingRequirement$ | async) }">
    <div class="wrapper">
      <div class="content">
        <div
          *ngIf="this.isCustomerWebAppAutoLoadPresetsFeatureEnabled && (currentTrainingRequirement$ | async)?.latestPresetDate != null && (currentTrainingRequirement$ | async).revisionNumber == 0">
          <daui-panel-info class="mt-1" [state]="state">
            This eTCA has been prefilled with the options previously saved on the eTCA submitted for the same course on
            {{ (currentTrainingRequirement$ | async)?.latestPresetDate | date: 'dd-MM-yyyy' }}
          </daui-panel-info>
        </div>
        <app-licence class="licence" [readonly]="readonly"
          [currentTrainingRequirement]="currentTrainingRequirement$ | async" [state]="state">
        </app-licence>
      </div>
    </div>
  </div>
</app-form-card>