import { Observable, Subscription, merge } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { ApprovalStatus, Approver, ApproverRole } from 'src/app/models/Approver';
import { SubmissionStatus } from 'src/app/models/SubmissionStatus';
import { TrainingRequirement, TrainingRequirementStatuses, TrainingType } from 'src/app/models/TrainingRequirement';
import { User, UserRoles } from 'src/app/models/User';
import { LoginState } from 'src/app/state/login.state';
import { LogAnalyticEvent } from 'src/app/state/trainingRequirement.actions';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { ApprovalState } from 'src/app/tca/approval/store';
import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';

import { BasePageComponent } from '../base-page/base-page.component';
import { Location } from '@angular/common';
import { ButtonAttractive, IndicatorType } from 'common-web-ui';
import { Authority } from 'src/app/models/Authority';
import { ChecktypeAdditionalInfo } from 'src/app/models/TrainingProfile';

@Component({
  selector: 'app-tca-state-page',
  templateUrl: './tca-state-page.component.html',
  styleUrls: ['./tca-state-page.component.less']
})
export class TcaStatePageComponent extends BasePageComponent implements OnInit, OnDestroy {

  _reservationId: string;

  get reservationId() {
    if (this.currentTrainingRequirement && this.currentTrainingRequirement.regulationAgency == RegulationAgencyName.TC) {
      return this.currentTrainingRequirement.schedulerReservationId;
    }
    else {
      return this._reservationId;
    }
  }

  subscriptions: Array<Subscription> = [];
  currentTrainingRequirement: TrainingRequirement = new TrainingRequirement();
  ApprovalStatus = ApprovalStatus;
  ApproverRole = ApproverRole;
  UserRoles = UserRoles;
  label = '';
  readonly buttonAttractive = ButtonAttractive;

  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;

  @Select(LoginState.userId)
  userId$: Observable<User>;

  @Select(ApprovalState.approvers)
  approvers$: Observable<Array<Approver>>;
  approvers: Array<Approver>;

  currentUser: User = new User();

  isShowDialog: boolean;
  isApprovalFeatureVisible = false;
  isShowApprovalButton = false;
  isApprovalButtonDisabled = false;
  currentApprovalStatus = ApprovalStatus.PENDING;
  currentApprovalStatusText = this.getApprovalText(this.currentApprovalStatus);
  currentStatusText = '';
  hasApprovers = false;
  isTcaLockedToggleFeatureEnabled: false;


  approverRoleDescriptions: any = {};

  /**
   * List of supporting documents for TC form
   */
  tcSupportingDocuments: { id: string, display: string, visible: boolean }[] = [
    { id: 'pilot-licence', display: 'Pilot Licence', visible: true },
    { id: 'medical-certificate', display: 'Medical Certificate', visible: true },
    { id: 'passport', display: 'Passport', visible: true },
    { id: 'company-country-specific-forms', display: 'Company, Cyclic or Country Specific Forms', visible: true },
    { id: 'latest-ops-spec', display: 'Latest Ops Spec', visible: true },
    { id: 'sop', display: 'SOP', visible: true },
    { id: 'required-new-atp-request', display: 'Required for New APT Request: CTP completion certificate, ATP exam report, Logbook, AFS-760', visible: false },
    { id: 'required-atpl-skill-test', display: 'Required for ATPL skill test: ATP Exam, MCC Certificate', visible: false },
  ];

  genericSupportingDocuments: { id: string, display: string, visible: boolean }[] = [
    { id: 'passport', display: 'Passport', visible: true },
    { id: 'pilot-licence', display: 'Pilot Licence & rating', visible: true },
    { id: 'medical-certificate', display: 'Medical Certificate', visible: true },
    { id: 'company-country-specific-forms', display: 'Authority or Company specific forms', visible: true },
    { id: 'latest-ops-spec', display: 'Latest Operational Manual (if company forms needs to be filled)', visible: true },
    { id: 'logbook', display: 'In addition to the above for Initial & Upgrade – Last six pages of logbook and Authority email approval (if applicable)', visible: true },
  ];

  readonly indicatorType = IndicatorType;

  get authority() {
    if (this.currentTrainingRequirement) {

      switch (this.currentTrainingRequirement.regulationAgency) {
        case RegulationAgencyName.EASA:
          return Authority.EASA;
        case RegulationAgencyName.TC:
          return Authority.TC;
        case RegulationAgencyName.FAA:
          return Authority.FAA;
        case RegulationAgencyName.Generic:
          return Authority.Generic;
        default:
          return Authority.None;
      }

    }
    else {
      return Authority.None;
    }
  }

  get visibleTCSupportingDocuments() {
    return this.tcSupportingDocuments.filter(x => x.visible);
  }

  get visibleGenericSupportingDocuments() {
    return this.genericSupportingDocuments.filter(x => x.visible);
  }

  get showSupportingDocumentsTC() {
    return !this.isApprovalFeatureVisible
      && this.currentTrainingRequirement
      && this.currentTrainingRequirement.regulationAgency.toString() === Authority.TC;
  }

  get showSupportingDocumentsGeneric() {
    return this.currentTrainingRequirement
      && this.currentTrainingRequirement.regulationAgency.toString() === Authority.Generic;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private location: Location
  ) {
    super();
    this.approverRoleDescriptions[ApproverRole.AccountExecutive] = 'Account Executive';
    this.approverRoleDescriptions[ApproverRole.Scheduler] = 'Scheduler';
    this.approverRoleDescriptions[ApproverRole.TrainingManager] = 'Training Manager';
  }

  ngOnInit() {
    super.onInit();
    this.userId$.subscribe(user => {
      this.currentUser = user;
    });

    this._reservationId = this.route.snapshot.params.id;

    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Load', 'TCA State Page'));
    this.subscribeToState();
  }

  subscribeToState() {
    this.subscribe(
      this.currentTrainingRequirement$.subscribe(currentTrainingRequirement => {
        this.currentTrainingRequirement = currentTrainingRequirement;

        if (this.currentTrainingRequirement.licences) {
          const hasFAALicence = this.currentTrainingRequirement.licences.find(l => l.authority === Authority.FAA);

          if (hasFAALicence && this.currentTrainingRequirement.trainingType === TrainingType.Initial) {
            const faaCheckType = this.currentTrainingRequirement.courseCheckTypesOverride.find(c => c.type === Authority.FAA);
            if (faaCheckType) {
              if (faaCheckType.valueOverride === 'New ATP') {
                this.tcSupportingDocuments[this.tcSupportingDocuments.findIndex(x => x.id === 'required-new-atp-request')].visible = true;
              }
              if (faaCheckType.additionalData && faaCheckType.additionalData[ChecktypeAdditionalInfo.AtplSkillTestRequired] === 'true') {
                this.tcSupportingDocuments[this.tcSupportingDocuments.findIndex(x => x.id === 'required-atpl-skill-test')].visible = true;
              }

            }
          }
        }

        // Disabled since AE Approval functionality is currently unused and not functioning correctly.
        // this.isApprovalFeatureVisible =
        //   this.currentTrainingRequirement.regulationAgency !== RegulationAgencyName.FAA &&
        //   this.currentTrainingRequirement.regulationAgency !== RegulationAgencyName.TC &&
        //   this.currentTrainingRequirement.regulationAgency !== RegulationAgencyName.Generic &&
        //   (this.currentTrainingRequirement.userRole === UserRoles.AccountExecutive ||
        //     this.currentTrainingRequirement.userRole === UserRoles.TrainingManager ||
        //     this.currentTrainingRequirement.userRole === UserRoles.Scheduler);
      })
    );

    this.subscribe(
      this.approvers$.pipe(combineLatest(this.userId$)).subscribe(([approvers, user]) => {
        this.updateApprovingPageStatus(approvers, user);
      })
    );
  }

  isTcaLocked(): boolean {
    let isTcaLocked = false;

    if (
      this.isTcaLockedToggleFeatureEnabled &&
      !!this.currentTrainingRequirement &&
      this.currentTrainingRequirement.status === TrainingRequirementStatuses.Locked
    ) {
      isTcaLocked = true;
    }

    return isTcaLocked;
  }

  updateApprovingPageStatus(approvers: Array<Approver>, user: User) {
    this.approvers = approvers;
    if (!!approvers && !!user) {
      const approver = approvers.find(
        a => a.email === (<any>user).email || a.userId === (<any>user).email
      );
      if (!!approver && approver.role === ApproverRole.AccountExecutive) {
        this.label =
          'As an Account Executive, you can edit the TCA in the behalf of your client. He will receive a confirmation by email. Please ensure that the updates are accepted by the customer.';
      }
      if (!!approver) {
        this.currentApprovalStatus = approver.approvalStatus;
        if (
          !this.getApprovedfor(approvers, ['AE', 'TM', 'SC'], 'Approved') &&
          this.currentApprovalStatus === ApprovalStatus.APPROVED
        ) {
          this.currentApprovalStatus = ApprovalStatus.PENDING;
        }
        this.currentApprovalStatusText = this.getApprovalText(this.currentApprovalStatus);
      }
      this.canDisplayApprovalButton(approvers);
      this.canDisableApprovalButton();
      this.getStatusText(approvers, this.currentTrainingRequirement.submissionStatus);
      this.hasApprovers = !!this.approvers && this.approvers.findIndex(a => a.email === '') < 0;
    }
  }

  getApprovalText(approvalState: ApprovalStatus) {
    switch (approvalState) {
      case ApprovalStatus.APPROVED:
        return 'APPROVED';
      case ApprovalStatus.PENDING:
        return 'APPROVAL PENDING';
      case ApprovalStatus.REJECTED:
        return 'REJECTED';
      default:
        break;
    }
  }

  getStatusText(approvers: Array<Approver>, submissionStatus: SubmissionStatus) {
    if (
      !!approvers &&
      approvers.length > 0 &&
      approvers.findIndex(a => a.email === '') >= 0 &&
      submissionStatus === SubmissionStatus.Submitted
    ) {
      this.currentStatusText =
        'The TCA has been submitted and needs to be first approved by an Account Executive.';
    } else if (
      submissionStatus === SubmissionStatus.Submitted &&
      (this.getApprovedfor(approvers, ['AE'], 'Approved') ||
        this.getApprovedfor(approvers, ['AE', 'TM'], 'Approved'))
    ) {
      this.currentStatusText = 'The TCA has been submitted and is waiting for your approval.';
    }
    if (this.getApprovedfor(approvers, ['AE', 'TM', 'SC'], 'Approved')) {
      this.currentStatusText =
        'The TCA has been approved and transmitted to the training administrator.';
    } else if (!!this.currentUser) {
      const currentUserApprover = approvers.find(
        a => a.email === this.currentUser.email || a.userId === this.currentUser.email
      );
      if (!!currentUserApprover && currentUserApprover.approvalStatus === ApprovalStatus.APPROVED) {
        this.currentStatusText = 'You approved the TCA.';
      }
    }
  }

  getApprovedfor(approvers: Array<Approver>, roles: string[], approvalStatus: string) {
    for (let index = 0; index < roles.length; index++) {
      const role = roles[index];
      const approver = approvers.find((approver: Approver) => {
        return approver.role === role;
      });
      if (!!approver && approver.approvalStatus !== approvalStatus) {
        return false;
      }
    }
    return approvers.length > 0;
  }

  ngOnDestroy() {
    super.onDestroy();
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Unload', 'TCA State Page'));
  }

  gotoReviewPage() {
    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Review'));
    this.router.navigate(['./tca-review'], { relativeTo: this.route });
  }

  gotoOtherReservations() {
    this.location.back();
  }

  canDisplayApprovalButton(approvers: Array<Approver>) {
    this.isShowApprovalButton = false;

    if (!!this.currentTrainingRequirement && !!this.currentTrainingRequirement.appRights) {
      const approvalReadWrite = this.currentTrainingRequirement.appRights.find(v => {
        return v === 'ApprovalReadWrite';
      });
      const approvalRead = this.currentTrainingRequirement.appRights.find(v => {
        return v === 'ApprovalRead';
      });

      if (approvalReadWrite || approvalRead) {
        this.isShowApprovalButton = true;

        if (!!this.currentUser) {
          const currentUserApprover = approvers.find(
            a => a.email === this.currentUser.email || a.userId === this.currentUser.email
          );
          if (
            !!currentUserApprover &&
            currentUserApprover.approvalStatus === ApprovalStatus.APPROVED
          ) {
            this.isShowApprovalButton = false;
          }
        }
      }
    }
  }

  canDisableApprovalButton() {
    if (!!this.currentTrainingRequirement && !!this.currentTrainingRequirement.appRights) {
      const approvalRead = this.currentTrainingRequirement.appRights.find(v => {
        return v === 'ApprovalRead';
      });
      if (approvalRead) {
        this.isApprovalButtonDisabled = true;
      }
    }
  }
  gotoEmailEdit(role: ApproverRole) {
    this.router.navigate(['./approval-email', { role }], { relativeTo: this.route });
  }
}
